<template>
  <div></div>
</template>

<script>
import getBoardId from '@/utils/getBoardId';
import dayjs from 'dayjs';
import { onMounted, onBeforeUnmount } from 'vue';

export default {
  props: ['boardId'],
  setup(props) {
    const newReloadPage = () => {
      if (typeof location !== 'undefined') location.reload();
    };

    const checkForNewBoard = () => {
      const todaysBoardId = getBoardId(dayjs().format('YYYY-MM-DD'));
      const boardIdFromUrl = window.location.pathname.split('/board/')[1];

      // reload the page if the current board is out of date
      // and user is not on a specific board page
      if (props.boardId !== '' && Number(props.boardId) !== todaysBoardId && !boardIdFromUrl) {
        newReloadPage();
      }
      return;
    }

    onMounted(() => {
      const newBoardCheck = setInterval(checkForNewBoard, 1000);

      onBeforeUnmount(() => {
        clearInterval(newBoardCheck);
      });
    });

    return;
  },
};
</script>