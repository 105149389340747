<template>
    <div class="container">
      <!-- v-if="hasVisitedBefore && !hasClosedPoll" -->
        <div class="close-btn" @click="handleClose">
            <div />
            <div />
        </div>
        <div v-if="state === 'initial'">
          <h1>We'd appreciate your feedback!</h1>
          <p class="info">We recently added Territories (e.g. Christmas Island, Falkland Islands) to GeoGrid. This provides consistency with our other games (e.g. Worldle, Flagle) and we believe it will enhance gameplay (more possible answers = more fun).</p>
          <p class="question">To what extent do you support this decision?</p>
            <div class="rating-container">
                <div v-for="(selection, index) in selections" 
                    :key="index" 
                    class="option" 
                    :class="{'highlight': hoveredIndex !== -1 ? hoveredIndex >= index : selectedIndex >= index}"
                    @mouseover="handleMouseOver(index)"
                    @click="handleMouseClick(index)"
                    @mouseleave="handleMouseLeave">
                    {{ index + 1 }}
                </div>
                <button class="btn" :disabled="selectedIndex === -1" @click="handleSubmit">Send</button>
            </div>
            <p class="label">{{ dynamicText }}</p>
        </div>

        <loading-spinner v-if="state === 'submitting'"/>

        <div v-if="state === 'voted'">
          <p>{{ this.responseMessage }}</p>
        </div>
    </div>
  </template>
  

  <script>
import LoadingSpinner from './loadingSpinner.vue';
import axios from 'axios';
import config from '../axiospath.js';

  export default {
    name: "UserPoll",
    components: {
        LoadingSpinner,
    },
    data() {
      return {
          selections: [
              'Bad idea',
              'Not a good idea',
              'Neutral',
              'Good idea',
              'Great idea'
          ],
          hoveredIndex: -1,
          selectedIndex: -1,
          state: 'initial',
          // hasVisitedBefore: false,
          // hasClosedPoll: false,
          responseMessage: '',
      }
    },
    methods: {
      handleMouseOver(index) {
        this.hoveredIndex = index;
      },
      handleMouseClick(index) {
        this.selectedIndex = index;
      },
      handleMouseLeave() {
        this.hoveredIndex = -1;
      },
      async getIp() {
        try {
        const response = await axios.get('https://api.ipify.org?format=json');
        return response.data.ip;
        } catch (error) {
          console.error('Error getting IP:', error);
          return null;
        }
      },
      async handleSubmit() {
        this.state = 'submitting';
        const ip = await this.getIp();
        if (!ip) {
          this.responseMessage = 'Failed to submit your vote. Please try again later.'
          return;
        }

        const voteData = {
          vote: this.selectedIndex,
          ip: ip,
        };

        try {
          const res = await axios.post(`${config.baseURL}/poll`, voteData);
          this.responseMessage = res.data.status === 'success' ? 'Your vote has been submitted!' : 'You have already voted.';
          if (res?.statusText === "OK") { 
            localStorage.setItem('voted', true);
            this.$emit('hide');
          }
        } catch (e) {
          console.error('Error submitting vote', e);
          this.responseMessage = 'Failed to submit your vote. Please try again later.';
        } finally {
          this.state = 'voted';
        }
      },
      handleClose() {
        localStorage.setItem('hasClosedPoll', true);
        this.$emit('close');
      },
      getColor(index) {
        if (index === 0) return 'rgb(220, 53, 69)';
        if (index === 1) return 'rgb(255, 133, 27)';
        if (index === 2) return 'rgb(255, 193, 7)';
        if (index === 3) return 'rgb(166, 184, 44)';
        if (index === 4) return 'rgb(76, 175, 80)';
      },
      getStyleIndex() {
        if (this.hoveredIndex !== -1) return this.hoveredIndex;
        else return this.selectedIndex;
      }
    },
    computed: {
        dynamicText() {
            if (this.hoveredIndex === -1 && this.selectedIndex === -1) {
                return '\u00A0';
            } else if (this.hoveredIndex > -1) {
                return this.selections[this.hoveredIndex];
            } else {
                return this.selections[this.selectedIndex];
            }
        }
    }
  };
  </script>
  

  <style scoped>
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  .container {
    background: #2a2a3a;
    width: 620px;
    color: white;
    position: absolute;
    z-index: 9999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 8px;
    padding: 24px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
    animation: slideInFromTop 1s;
  }
  .info {
    margin: 1.5rem 0 2rem;
    /* background: rgba(0,0,0,0.2);
    padding: 4px 0;
    border-radius: 4px; */
  }
  .question{
    font-weight: bold;
    margin-bottom: 1rem;
  }
  .rating-container {
    display: flex;
    justify-content: center;
    gap: 8px;
  }
  .option {
    width: 50px;
    height: 40px;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid;
    border-top-color: rgba(255, 255, 255, 0.3);
    border-left-color: rgba(0, 0, 0, 0.2);
    border-bottom-color: rgba(0, 0, 0, 0.4);
    border-right-color: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  .highlight {
    background-color: v-bind(getColor(getStyleIndex()));
  }
  .label{
    margin-top: 16px;
  }
  .btn{
    background: rgba(255,255,255,0.12);
    border: none;
    border-radius: 4px;
    color: white;
    font-family: inherit;
    font-weight: bold;
    font-size: 1rem;
    padding: 8px 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .btn:disabled:hover{
    background: rgba(255,255,255,0.12);
  }
  .btn:not(:disabled):hover{
    background: rgba(255,255,255,0.2);
  }
  .btn:disabled{
    opacity: 0.5;
    cursor: default;
  }
  .close-btn{
    position: absolute;
    width: 40px;
    height: 40px;
    top: 0px;
    right: 0px;
    cursor: pointer;
  }
  .close-btn > *{
    background: rgb(220, 53, 69);
    width: 18px;
    height: 2px;
    position: absolute;
    top: calc(50% - 1px);
    left: calc(50% - 9px);
  }
  .close-btn > *:first-child{
    transform: rotateZ(45deg) ;
  }
  .close-btn > *:last-child{
    transform: rotateZ(-45deg);
  }

  @media screen and (max-width: 992px) {
    .container{
      width: 500px;
    }
  }
  @media screen and (max-width: 768px) {
    .container{
     width: 420px;
     font-size: .85rem;
    }
    .btn{
        font-size: .85rem;
    }
  }
  @media screen and (max-width: 480px) {
    .container{
        width: 85%;
    }
    h1{
        font-size: 1.3rem;
    }
    .info{
        font-size: 13px;
    }
    .rating-container{
        gap: 5px;
    }
    .option{
        font-size: 12px;
        width: 40px;
        height: auto;
    }
    .btn{
        font-size: 12px;
    }
    .close-btn{
        width: 30px;
        height: 30px;
        top: 2px;
        right: 2px;
    }
    .close-btn > *{
        width: 14px;
    }
  }
  @media screen and (max-width: 375px) {
    .option{
        width: 30px;
        height: 30px;
    }
    .btn{
        height: 30px;
    }
  }
  @keyframes slideInFromTop {
    0% {
      transform: translate(-50%, -200%);
      opacity: 0;
    }
    100% {
      transform: translate(-50%, -50%);
      opacity: 1;
    }
}
  </style>
  
