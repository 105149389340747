import { getCountryFlagSvgUrl } from './fetchDataFromCDN';
import sanitizeString from './sanitizeString';
import { updateOldNamesToMatchCDNNames } from './updateOldCountryName';

export default function getImageUrl(countryName) {
  //check if URL already cached
  if (Object.prototype.hasOwnProperty.call(this.imageUrlCache, countryName)) {
    return this.imageUrlCache[countryName];
  } else {
    try {
      // Update the name to match CDN names if needed
      countryName = updateOldNamesToMatchCDNNames(countryName);

      // Fetch the image URL
      const country = this.countryData.find(
        (country) =>
          sanitizeString(country.name) === sanitizeString(countryName)
      );
      if (country) {
        const countryPrefix = country.code;
        const imageUrl = getCountryFlagSvgUrl(countryPrefix);

        // Cache the fetched URL
        this.imageUrlCache[countryName] = imageUrl;

        return imageUrl;
      } else {
        // Handle case where country data is not found
        console.error('Country data not found for:', countryName);
        return '';
      }
    } catch (error) {
      console.error('Error fetching image URL:', error);
      return '';
    }
  }
}