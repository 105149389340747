<!-- ADD META TAGS FOR SEARCH BOOSTING -->
<template>
  <div>
    <nav class="top-nav">
      <a class="logo" href="https://teuteuf.fr" target="_blank" rel="noopener noreferrer"><img src="/teuteuf.svg"
          alt="teuteuf logo" /></a>
      <div class="top-nav-inner">
        <button @click="showCategoryInfoPopup" class="nav-btn">
          <div class="tooltip">Categories Info</div>

          <span style="font-size: 20px; display: flex"><svg xmlns="http://www.w3.org/2000/svg" width="28" height="24"
              viewBox="0 0 512 512">
              <path fill="#0cc46e"
                d="M40 48C26.7 48 16 58.7 16 72l0 48c0 13.3 10.7 24 24 24l48 0c13.3 0 24-10.7 24-24l0-48c0-13.3-10.7-24-24-24L40 48zM192 64c-17.7 0-32 14.3-32 32s14.3 32 32 32l288 0c17.7 0 32-14.3 32-32s-14.3-32-32-32L192 64zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32l288 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-288 0zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32l288 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-288 0zM16 232l0 48c0 13.3 10.7 24 24 24l48 0c13.3 0 24-10.7 24-24l0-48c0-13.3-10.7-24-24-24l-48 0c-13.3 0-24 10.7-24 24zM40 368c-13.3 0-24 10.7-24 24l0 48c0 13.3 10.7 24 24 24l48 0c13.3 0 24-10.7 24-24l0-48c0-13.3-10.7-24-24-24l-48 0z" />
            </svg></span>
        </button>
        <button onclick="window.open('https://twitter.com/geogridgame', '_blank')" class="nav-btn">
          <div class="tooltip">Our Twitter</div>

          <span style="font-size: 20px; display: flex"><svg width="28" height="24" xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512">
              <path fill="#1da1f2"
                d="M459.4 151.7c.3 4.5 .3 9.1 .3 13.6 0 138.7-105.6 298.6-298.6 298.6-59.5 0-114.7-17.2-161.1-47.1 8.4 1 16.6 1.3 25.3 1.3 49.1 0 94.2-16.6 130.3-44.8-46.1-1-84.8-31.2-98.1-72.8 6.5 1 13 1.6 19.8 1.6 9.4 0 18.8-1.3 27.6-3.6-48.1-9.7-84.1-52-84.1-103v-1.3c14 7.8 30.2 12.7 47.4 13.3-28.3-18.8-46.8-51-46.8-87.4 0-19.5 5.2-37.4 14.3-53 51.7 63.7 129.3 105.3 216.4 109.8-1.6-7.8-2.6-15.9-2.6-24 0-57.8 46.8-104.9 104.9-104.9 30.2 0 57.5 12.7 76.7 33.1 23.7-4.5 46.5-13.3 66.6-25.3-7.8 24.4-24.4 44.8-46.1 57.8 21.1-2.3 41.6-8.1 60.4-16.2-14.3 20.8-32.2 39.3-52.6 54.3z" />
            </svg></span>
        </button>
        <button onclick="window.open('https://forms.gle/FQf2vKV5DnDLz7XMA', '_blank')" class="nav-btn">
          <div class="tooltip">Submit Feedback</div>

          <span style="font-size: 20px; display: flex"><svg width="28" height="24" xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512">
              <path fill="#ccc" stroke="#000" stroke-width="10"
                d="M512 240c0 114.9-114.6 208-256 208c-37.1 0-72.3-6.4-104.1-17.9c-11.9 8.7-31.3 20.6-54.3 30.6C73.6 471.1 44.7 480 16 480c-6.5 0-12.3-3.9-14.8-9.9c-2.5-6-1.1-12.8 3.4-17.4c0 0 0 0 0 0s0 0 0 0s0 0 0 0c0 0 0 0 0 0l.3-.3c.3-.3 .7-.7 1.3-1.4c1.1-1.2 2.8-3.1 4.9-5.7c4.1-5 9.6-12.4 15.2-21.6c10-16.6 19.5-38.4 21.4-62.9C17.7 326.8 0 285.1 0 240C0 125.1 114.6 32 256 32s256 93.1 256 208z" />
            </svg>
          </span>
        </button>
        <button @click="showGameInfoPopup" class="nav-btn">
          <div class="tooltip">Game Info</div>

          <span style="font-size: 20px; display: flex"><svg width="28" height="24" xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 320 512">
              <path fill="#f2314e"
                d="M80 160c0-35.3 28.7-64 64-64l32 0c35.3 0 64 28.7 64 64l0 3.6c0 21.8-11.1 42.1-29.4 53.8l-42.2 27.1c-25.2 16.2-40.4 44.1-40.4 74l0 1.4c0 17.7 14.3 32 32 32s32-14.3 32-32l0-1.4c0-8.2 4.2-15.8 11-20.2l42.2-27.1c36.6-23.6 58.8-64.1 58.8-107.7l0-3.6c0-70.7-57.3-128-128-128l-32 0C73.3 32 16 89.3 16 160c0 17.7 14.3 32 32 32s32-14.3 32-32zm80 320a40 40 0 1 0 0-80 40 40 0 1 0 0 80z" />
            </svg></span>
        </button>
      </div>
    </nav>
    <ContentUpdateToast />
    <DateChecker :boardId="boardId" />
    <h1 class="page-title">GeoGrid 🌎 Board #{{ boardId }} </h1>
    <game-info v-if="isGameInfoPopup" @close="hideGameInfoPopup" />
    <categoryInfo v-if="isCategoryInfoPopup" @close="hideCategoryInfoPopup" />

    <UserPoll v-if="isPollOpen" @close="closePoll" @hide="hidePollButton"/>

    <button @click="showPrevBoardPopup" class="prev-grids-btn btn">Play Previous Grids!</button>
    <button @click="openPoll" class="btn poll-btn" v-if="!voted">Help us decide! (Poll)</button>
    <prev-board-popup v-if="isPrevBoardPopup" @select-board="hidePrevBoardPopup" @close="hidePrevBoardPopup" />
    <!-- sidebar ad left -->
    <sb-left-ad></sb-left-ad>
    <loading-spinner v-if="loading" />
    <div v-else>
      <div :key="gameData" v-if="gameData && countryData">
        <div class="board">
          <div class="row">
            <div class="filler" />
            <div v-for="(column, index) in gameData.columns" :key="'col-' + index" class="prompt column-prompt">
              {{ column.name }}
            </div>
          </div>
          <div v-for="(row, rowIndex) in gameData.rows" :key="'row-' + rowIndex" class="row">
            <div class="prompt row-prompt">
              {{ row.name }}
            </div>
            <div v-for="(column, colIndex) in gameData.columns" :key="'col-' + colIndex" class="box"
              @click="!isGameOver && !board[rowIndex][colIndex] && showSearch(rowIndex, colIndex)" :class="{
                'highlighted': searching && selectedRowIndex === rowIndex && selectedColIndex === colIndex,
                'wrong animate-shake': wrongGuessLocation.length === 2 && wrongGuessLocation[0] === rowIndex && wrongGuessLocation[1] === colIndex,
                'guessed': isGameOver || board[rowIndex][colIndex],
              }">
              <div class="rarity-element" v-if="board[rowIndex][colIndex] !== ''"
                :style="getRarityStyle(rowIndex, colIndex)">{{ getRarityText(rowIndex, colIndex) }}</div>
              <div v-if="board[rowIndex][colIndex] !== ''" class="flag-container">
                <img
                  v-if="guessedCountries.includes(board[rowIndex][colIndex]) && getImageUrl(board[rowIndex][colIndex]) !== ''"
                  :src="getImageUrl(board[rowIndex][colIndex])" alt="Country flag" class="country-flag" />
              </div>
              <template v-if="board[rowIndex][colIndex] !== ''">
                <div class="country-info">{{ board[rowIndex][colIndex] }}</div>
              </template>
            </div>
          </div>
        </div>
        <!-- mobile mrec ad -->
        <mobile-mrec-ad></mobile-mrec-ad>
        <div class="guesses-and-score">
          <h2>Guesses left: {{ infiniteMode ? '∞' : guesses }} &nbsp; &nbsp; Score: {{ score }}</h2>
        </div>
      </div>
      <p v-else style="color: white;">No data available</p>
    </div>
    <button v-if="!isGameOver" @click="toggleInfiniteMode" :class="{ selected: infiniteMode }" class="infinity-btn btn"
      :disabled="isGameOver">
      {{ infiniteMode ? 'Disable Infinite Mode' : 'Enable Infinite Mode' }}
    </button>
    <button v-if="!isGameOver" class="restart-btn btn" @click="restartOrGiveUp()"
      :disabled="!anyGuessPlaced() || isGameOver">
      {{ isGameOver ? 'Game Over' : 'Give Up' }}
    </button>
    <button v-if="isGameOver" @click="showGameOver" class="show-summary-btn btn">Show Summary</button>
    <p class="category-help-msg">Click the <span><svg style="position: relative; top: 5px;"
          xmlns="http://www.w3.org/2000/svg" width="24" height="22" viewBox="0 0 512 512">
          <path fill="#0cc46e"
            d="M40 48C26.7 48 16 58.7 16 72l0 48c0 13.3 10.7 24 24 24l48 0c13.3 0 24-10.7 24-24l0-48c0-13.3-10.7-24-24-24L40 48zM192 64c-17.7 0-32 14.3-32 32s14.3 32 32 32l288 0c17.7 0 32-14.3 32-32s-14.3-32-32-32L192 64zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32l288 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-288 0zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32l288 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-288 0zM16 232l0 48c0 13.3 10.7 24 24 24l48 0c13.3 0 24-10.7 24-24l0-48c0-13.3-10.7-24-24-24l-48 0c-13.3 0-24 10.7-24 24zM40 368c-13.3 0-24 10.7-24 24l0 48c0 13.3 10.7 24 24 24l48 0c13.3 0 24-10.7 24-24l0-48c0-13.3-10.7-24-24-24l-48 0z" />
        </svg></span> icon for help with a category</p>
    <!-- sidebar ad right -->
    <sb-right-ad></sb-right-ad>
    <!-- Popups -->
    <search-popup v-if="showSearchPopup && countryData" :guessed-countries="board.flat()"
      :countries="countryData.map(c => c.name)" @close="hideSearch" @guess="handleGuess" />
    <game-over v-if="showGameOverPopup" :score="score" :boardId="boardId" :board="board"
      :liveRarityData="liveRarityData" :gameData="gameData" :countryData="countryData" :answersList="answersList"
      @close="hideGameOver" />
  </div>
</template>

<script>
import axios from 'axios';
import dayjs from 'dayjs';
import SearchPopup from './searchPopup.vue';
import GameOver from './gameOver.vue';
import GameInfo from './gameInfo.vue';
import PrevBoardPopup from './prevBoardPopup.vue';
import config from '../axiospath.js';
import categoryInfo from './categoryInfo.vue';
import SbLeftAd from './ads/sbLeftAd.vue';
import SbRightAd from './ads/sbRightAd.vue';
import MobileMrecAd from './ads/mobileMrecAd.vue';
import LoadingSpinner from './loadingSpinner.vue';
import ContentUpdateToast from './toasts/contentUpdateToast.vue';
import DateChecker from './dateChecker.vue';
import getBoardId from '@/utils/getBoardId';
import getImageUrl from '@/utils/getImageUrl';
import UserPoll from './UserPoll.vue';
import { getBoard, getCountries } from '@/utils/fetchDataFromCDN'

export default {
  components: {
    SearchPopup,
    GameOver,
    PrevBoardPopup,
    GameInfo,
    categoryInfo,
    SbLeftAd,
    SbRightAd,
    MobileMrecAd,
    LoadingSpinner,
    ContentUpdateToast,
    DateChecker,
    UserPoll
  },
  data() {
    return {
      gameData: null,
      countryData: null,
      liveRarityData: null,
      board: [
        ['', '', ''],
        ['', '', ''],
        ['', '', '']
      ],
      rarityPercentages: [],
      guesses: 10,
      guessedCountries: [],
      showSearchPopup: false,
      selectedRowIndex: -1,
      selectedColIndex: -1,
      isGameOver: false,
      infiniteMode: false,
      searching: false,
      boardId: '',
      isPrevBoardPopup: false,
      isGameInfoPopup: false,
      isCategoryInfoPopup: false,
      showGameOverPopup: false,
      isguessing: false,
      imageUrlCache: {},
      score: 900,
      wrongGuessLocation: [],
      loading: true,
      isPollOpen: false,
      voted: false,
    };
  },
  async mounted() {
    // Check if the user has visited the site before
    const hasVisitedBefore = localStorage.getItem('hasVisitedBefore');
    if (hasVisitedBefore) {
      this.hasVisitedBefore = true;
    } else {
      this.isGameInfoPopup = true;
      this.hasVisitedBefore = true;
      localStorage.setItem('hasVisitedBefore', true);
    }
    // Check if user has already voted
    this.voted = localStorage.getItem('voted');

    // Get the board ID from the URL
    const boardIdFromUrl = window.location.pathname.split('/board/')[1];
    // Get todays board ID from todays date
    const todaysBoardId = getBoardId(dayjs().format('YYYY-MM-DD'));

    if (boardIdFromUrl) {
      this.boardId = boardIdFromUrl;

      // don't show future board data
      if (Number(boardIdFromUrl) > todaysBoardId) {
        this.loading = false;
        return;
      }

      this.loadDataFromLocalStorage();
    } else {
      try {
        this.boardId = todaysBoardId.toString();
        this.loadDataFromLocalStorage();
      } catch (error) {
        console.error('Error fetching boards:', error);
      }
    }

    // Tooltips
    document.querySelectorAll(".nav-btn").forEach((button) => {
      const tooltip = button.querySelector(".tooltip");
      const rect = button.getBoundingClientRect();
      tooltip.style.left = `${rect.width / 2}px`;
      tooltip.style.top = `${rect.height + 10}px`;

      // Check if the tooltip goes out of bounds on the right
      const tooltipRect = tooltip.getBoundingClientRect();
      if (tooltipRect.right > window.innerWidth) {
        tooltip.style.left = `${-(tooltipRect.right - window.innerWidth)}px`;
      }
    });

    // Poll stuff
    const hasClosedPoll = localStorage.getItem('hasClosedPoll');
    // 1. If visited before and didn't close poll, show
    if (hasVisitedBefore && !hasClosedPoll && !this.voted) this.isPollOpen = true;

    // Fetch game data, answers, country data, and live rarity data
    await Promise.all([
      this.fetchGameData(),
      this.getCountryData(),
      this.getLiveRarity()
    ]);

    this.loading = false;
  },

  watch: {
    // Load data from local storage when boardId changes
    boardId(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.loadDataFromLocalStorage();
      }
    },

    // Fetch game data when boardId changes
    async '$route.params.board_id'(newValue) {
      if (newValue !== this.boardId) {
        if (Number(newValue) > getBoardId(dayjs().format('YYYY-MM-DD'))) {
          // don't show future board data
          this.boardId = newValue;
          return;
        }
        this.loading = true;
        this.boardId = newValue;
        this.infiniteMode = false;
        await Promise.all([
          this.fetchGameData(),
          this.getLiveRarity()
        ]);
        this.loading = false;
      }
    }
  },
  methods: {

    // Fetch board from the CDN
    async fetchGameData() {
      this.gameData = null;
      this.answersList = null;
      try {
        this.gameData = await getBoard(this.boardId);
        this.answersList = this.gameData.answers;
      } catch (error) {
        console.error('Error fetching game data:', error);
      }
    },

    // Show the search popup for a specific box
    showSearch(rowIndex, colIndex) {
      this.selectedRowIndex = rowIndex;
      this.selectedColIndex = colIndex;
      this.showSearchPopup = true;
      this.searching = true;
    },

    // Hide the search popup
    hideSearch() {
      this.showSearchPopup = false;
      this.searching = false;
    },

    // Restart the game, reset all data
    restartGame() {
      this.deleteUserDataForCurrentBoard();

      this.isGameOver = false;
      this.guesses = 10;
      this.score = 900;
      this.guessedCountries = [];
      this.infiniteMode = false;
      this.board = [
        ['', '', ''],
        ['', '', ''],
        ['', '', '']
      ];
      this.rarityPercentages = [];
      this.initializeRarityPercentages();
      this.deleteUserDataForCurrentBoard();
    },

    // Show the game over popup
    showGameOver() {
      this.showGameOverPopup = true;
    },

    // Hide the game over popup
    hideGameOver() {
      this.showGameOverPopup = false;
    },

    // Show the previous board popup
    showPrevBoardPopup() {
      this.isPrevBoardPopup = true;
    },

    // Hide the previous board popup
    hidePrevBoardPopup() {
      this.isPrevBoardPopup = false;
    },

    // Show the game info popup
    showGameInfoPopup() {
      this.isGameInfoPopup = true;
    },

    // Hide the game info popup
    hideGameInfoPopup() {
      this.isGameInfoPopup = false;
    },

    // Show the category info popup
    showCategoryInfoPopup() {
      this.isCategoryInfoPopup = true;
    },

    // Hide the category info popup
    hideCategoryInfoPopup() {
      this.isCategoryInfoPopup = false;
    },
    // Open poll
    openPoll() {
      this.isPollOpen = true;
    },

    // Fetch country list from CDN
    async getCountryData() {
      try {
        this.countryData = await getCountries();
      } catch (error) {
        console.error('Error fetching country data:', error);
      }
    },

    // Fetch live rarity data from the API
    async getLiveRarity() {
      try {
        const liveRarityDataResponse = await axios.get(`${config.baseURL}/game/rarity/` + this.boardId);
        this.liveRarityData = liveRarityDataResponse.data;
      } catch (error) {
        console.error('Error fetching country data:', error);
      }
    },

    // Toggle infinite mode
    toggleInfiniteMode() {
      if (!this.infiniteMode) {
        this.previousGuesses = this.guesses;
      } else {
        this.guesses = this.previousGuesses;
      }
      this.infiniteMode = !this.infiniteMode;
    },
    // Handle the guess made by the user
    async handleGuess(guess) {
      if (this.isGuessing) {
        return;
      }

    this.isGuessing = true;
    //increment play count when first guess placed
    if (this.guessedCountries.length === 0) {
      const url = `${config.baseURL}/game/stats/${this.boardId}?score=${this.score}&flag=1`;
      try {
        axios.post(url);
      } catch (error) {
        console.error('Error making request:', error);
      }
    }
    // Check if the guess has already been used
    if (this.checkIfCountryHasBeenUsed(guess)) {
      return;
    }

      // Make the guess
      try {
        const index =
          this.selectedRowIndex * this.gameData.columns.length +
          this.selectedColIndex +
          1;

        this.guessedCountries.push(guess);

        const correct = this.answersList['match_box_' + index].includes(guess);

        // Update rarity
        if (correct) {
          const url = `${config.baseURL}/game/rarity/${this.boardId}?guess=${guess}&index=${index}`;
          try {
            axios.post(url);
          } catch (error) {
            console.error('Error making request:', error);
          }

          //calculate rarity of guess
          const total = this.liveRarityData['match_box_' + index]['total'];
          const count = this.liveRarityData['match_box_' + index][guess];
          let rarityPercentage;

          rarityPercentage = ((count / total) * 100).toFixed(1);
          if (isNaN(rarityPercentage)) {
            rarityPercentage = 0;
          }
          this.score = (this.score - (100 - rarityPercentage)).toFixed(1);

          // Ensure rarityPercentages[this.selectedRowIndex] is initialized as an array
          if (!this.rarityPercentages[this.selectedRowIndex]) {
            this.rarityPercentages[this.selectedRowIndex] = [];
          }

          // Set rarityPercentage value
          this.rarityPercentages[this.selectedRowIndex][this.selectedColIndex] =
            rarityPercentage;
          this.board[this.selectedRowIndex][this.selectedColIndex] = guess;
          this.getImageUrl(guess);
        }

        // Hide the search popup
        this.hideSearch();
        if (!this.infiniteMode) {
          this.guesses--;
        }

        // Show wrong animation on box
        if (!correct) {
          this.setWrongGuessLocation();
        }

      // Check if the game is won or lost
      if (this.checkIfWon()) {
        this.showGameOver();
        this.isGameOver = true;
      } else if (this.guesses === 0) {
        // this.getLiveRarity()
        this.showGameOver();
        this.isGameOver = true;
      }
    } catch (error) {
      console.error('Error making guess:', error);
    }finally {
      // Reset the flag when the guess request is complete
      this.isGuessing = false;
    }
    this.saveDataToLocalStorage();
  },

    // Check if country has been used
    checkIfCountryHasBeenUsed(guess) {
      if (this.board.flat().some(box => box === guess)) {
        return true;
      } else {
        return false;
      }
    },

    setWrongGuessLocation() {
      this.wrongGuessLocation = [this.selectedRowIndex, this.selectedColIndex];
      setTimeout(() => {
        this.wrongGuessLocation = [];
      }, 500)
    },

    // Check if the game is won
    checkIfWon() {
      if (this.board.flat().every(box => box !== '')) {
        // this.getLiveRarity();
        return true;
      } else {
        return false;
      }
    },

    // Get the image URL of the guessed country
    getImageUrl,

    // Give up the game
    giveUp() {
      // this.getLiveRarity();
      this.isGameOver = true;
      this.saveDataToLocalStorage();
      this.showGameOverPopup = true;
    },

    // Restart the game or give up based on the game state
    restartOrGiveUp() {
      if (this.isGameOver) {
        this.restartGame();
        // this.getLiveRarity();
      } else {
        this.giveUp();
      }
    },

    // Initialize rarity percentages array
    initializeRarityPercentages() {
      this.rarityPercentages = this.board.map(row => Array(row.length).fill(''));
    },

    // Function to get the rarity text based on the percentage
    getRarityText(rowIndex, colIndex) {
      const rarityPercentage = this.rarityPercentages[rowIndex][colIndex];
      if (rarityPercentage === 0) {
        return 'Legendary - First';
      } else {
        const parsedPercentage = parseFloat(rarityPercentage);
        if (!isNaN(parsedPercentage)) {
          if (parsedPercentage >= 30) {
            return `Common - ${parsedPercentage}%`;
          } else if (parsedPercentage >= 20) {
            return `Uncommon - ${parsedPercentage}%`;
          } else if (parsedPercentage >= 10) {
            return `Rare - ${parsedPercentage}%`;
          } else if (parsedPercentage >= 5) {
            return `Epic - ${parsedPercentage}%`;
          } else {
            return `Legendary - ${parsedPercentage}%`;
          }
        } else {
          return `Legendary - ${rarityPercentage}`;
        }
      }
    },

    // Function to get the rarity styling based on the percentage
    getRarityStyle(rowIndex, colIndex) {
      const rarityPercentage = parseFloat(this.rarityPercentages[rowIndex][colIndex]);
      if (rarityPercentage >= 30) {
        return { backgroundColor: 'rgb(101, 208, 101)' };
      } else if (rarityPercentage >= 20) {
        return { backgroundColor: 'rgb(101, 139, 208)' };
      } else if (rarityPercentage >= 10) {
        return { backgroundColor: 'rgb(150, 112, 221)' };
      } else if (rarityPercentage >= 5) {
        return { backgroundColor: 'rgb(221, 112, 197)' };
      } else {
        return { backgroundColor: 'rgb(225, 53, 53)' };
      }
    },

    // Check if any guess has been placed
    anyGuessPlaced() {
      return this.guessedCountries.length > 0;
    },


    // Save the game data to local storage
    saveDataToLocalStorage() {
      // Get existing user data from localStorage
      const userDataString = localStorage.getItem('userData');
      let userData = {};

      // Parse the JSON string to get the saved data object
      if (userDataString) {
        userData = JSON.parse(userDataString);
      }

      // Check if data for the current boardId already exists
      if (!userData[this.boardId]) {
        userData[this.boardId] = {}; // If not, create a new object for the boardId
      }

      // Update the component's state in the userData object
      userData[this.boardId] = {
        guessedCountries: this.guessedCountries,
        guesses: this.guesses,
        isGameOver: this.isGameOver,
        board: this.board,
        rarityPercentages: this.rarityPercentages,
        score: this.score
        // Add other relevant data properties here
      };

      // Save the updated userData object to localStorage
      localStorage.setItem('userData', JSON.stringify(userData));
    },

    // Load the game data from local storage
    loadDataFromLocalStorage() {

      // Check if there is saved data for the current boardId in local storage
      const userDataString = localStorage.getItem('userData');
      if (userDataString) {
        // Parse the JSON string to get the saved data object
        const userData = JSON.parse(userDataString);
        if (userData[this.boardId]) { // Check if data for current boardId exists
          // Update the component's state with the loaded data
          const savedData = userData[this.boardId];
          this.guessedCountries = savedData.guessedCountries;
          this.guesses = savedData.guesses;
          this.isGameOver = savedData.isGameOver;
          this.infiniteMode = savedData.infiniteMode;
          this.board = savedData.board;
          this.rarityPercentages = savedData.rarityPercentages;
          this.score = savedData.score;
          // Add other relevant data properties here
        } else {
          // Reset component's state if no data is found for the current boardId
          this.resetState();
        }
      } else {
        // Reset component's state if no data is found in local storage
        this.resetState();
      }
    },

    // Delete user data for the current board from local storage
    deleteUserDataForCurrentBoard() {
      const userDataString = localStorage.getItem('userData');
      const userLeaderboardDataString = localStorage.getItem('leaderboard_data_' + this.boardId);

      if (userDataString) {
        const userData = JSON.parse(userDataString);
        if (userData[this.boardId]) {
          delete userData[this.boardId];
          localStorage.setItem('userData', JSON.stringify(userData));
        }
      }

      if (userLeaderboardDataString) {
        localStorage.removeItem('leaderboard_data_' + this.boardId);
      }
    },

    // Reset the component's state
    resetState() {
      this.guessedCountries = [];
      this.guesses = 10;
      this.isGameOver = false;
      this.infiniteMode = false;
      this.board = [
        ['', '', ''],
        ['', '', ''],
        ['', '', '']
      ];
      this.rarityPercentages = [];
      this.score = 900;
    },

    sendEmail() {
      // Prompt user to send email
      const email = 'geogridgame@gmail.com';
      const mailtoLink = `mailto:${email}`;

      window.location.href = mailtoLink;
    },
    isFirefox() {
      return navigator.userAgent.includes('Firefox');
    },

    // Poll stuff
    closePoll() {
      this.isPollOpen = false;
    },

    hidePollButton() {
      this.voted = true;
    }
  }
};
</script>

<style>
:root {
  background-color: rgb(53, 53, 72);
  --cell-width: 150px;
  --cell-width-tablet: 120px;
  --cell-width-mobile: 90px;
}

.page-title {
  margin-bottom: -1px;
  color: rgb(223, 223, 223);
}

.logo {
  width: 200px;
  max-width: 100%;
}

.logo img{
  width: 100%;
  height: auto;
}

.filler{
  width: var(--cell-width);
}

.prompt {
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(223, 223, 223);
  font-size: 14px;
  background-color: rgba(255, 255, 255, 0.1);
  width: var(--cell-width);
  min-height: 60px;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 6px;
}

.row-prompt{
  height: var(--cell-width);
}

.board {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 150px;
  gap: 4px;
}

.row {
  display: flex;
  align-items: stretch;
  height: var(--cell-width);
  gap: 4px;
}

.row:first-child{
  height: max-content;
}

.box {
  width: var(--cell-width);
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.25s;
  border-radius: 6px;
  box-sizing: border-box;
  padding: 6px;
}
.btn{
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.5);
  color: #fff;
  border: none;
  padding: 6px 12px;
  border-radius: 5px;
  transition: background-color 0.3s;
  font-family: 'Poppins', Helvetica, Arial, sans-serif;
}

.guessed{
  cursor: initial;
}
.box:not(.guessed):hover {
  background-color: rgba(255, 255, 255, 0.5);
}

.highlighted {
  background-color: rgb(252, 252, 0);
}

.wrong {
  background-color: #AA0817;
  transition: background-color 0.25s;
}
.prev-grids-btn {
  margin-top: 10px;
  margin-bottom: 20px;
 
}

.restart-btn, .infinity-btn, .show-summary-btn {
  margin-bottom: 15px;
}

.restart-btn, .show-summary-btn {
  margin-left: 10px;
}

.btn:hover {
  background-color: rgb(223, 223, 223);
}
.flag-container{
  width: 100%;
  flex-grow: 1;
  overflow: hidden;
  margin: 6px 0;
}
.country-flag {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.country-info {
  width: 100%;
  min-height: 14%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  box-sizing: border-box;
  overflow: hidden;
  font-size: 12px;
  color: white;
  border-radius: 3px;
  flex-shrink: 0;
  background-color: #373768;
}
.rarity-element {
  width: 100%;
  height: 11%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 10px;
  font-weight: bold;
  border-radius: 3px;
  
}

.guesses-and-score {
  margin-bottom: 5px;
  margin-right: 20px;
  color: rgb(223, 223, 223);
}

.top-nav {
  box-sizing: border-box;
  position: fixed;
  width: 100%;
  top: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  z-index: 100;
  padding: 24px 1.5rem;

}
.top-nav-inner {
  display: flex;
  gap: 0.5rem;
}

.nav-btn {
  padding: 5px;
  border: none;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.1);
  cursor: pointer;
  position: relative;
}

.nav-btn:hover {
  background-color: rgb(223, 223, 223);
}

.tooltip {
  position: absolute;
  background: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  white-space: nowrap;
  z-index: 1000;
  opacity: 0;
  transition: opacity 0.4s;
  pointer-events: none;
}

.nav-btn:hover .tooltip {
  opacity: 1;
}

.category-help-msg {
  color: rgb(134, 136, 164);
  font-size: 12px;
  margin-top: 1px;
  font-weight: bold;
}

.llc-msg {
  color: rgb(134, 136, 164);
  font-size: 12px;
  font-weight: bold;
}

.contact-msg {
  color: rgb(134, 136, 164);
  font-size: 12px;
  font-weight: bold;
  margin-left: 20px;
}

.plays-msg {
  color: rgb(134, 136, 164);
  font-size: 14px;
  margin-top: 2px;
  margin-bottom: 2px;
  font-weight: bold;
}
.poll-btn{
  margin-left: 6px;
  background-size: 200% 100%;
  background-image: linear-gradient(
    to right, 
    #e46748, 
    #feb47b, 
    #e46748
  );
  animation: gradientMove 3s infinite linear;
  transition: 0.6s;
}
.poll-btn:hover{
  box-shadow: 0 0 10px rgba(228, 103, 72, 0.6), 
              0 0 20px rgba(254, 180, 123, 0.4);
}

@media screen and (max-width: 992px) {
  .top-nav {
    position: static;
  }
  .page-title{
    margin: 0;
  }
  .board{
    margin-right: 0;
    margin-left: -25px;
  }
  .guesses-and-score{
    margin-right: 0;
  }
}

@media screen and (max-width: 768px) {
  .plays-msg {
    font-size: 12px;
  }
  .llc-msg,
  .contact-msg {
    font-size: 10px;
  }
  .board{
    gap: 3px;
  }
  .filler{
    width: var(--cell-width-tablet);
  }
  .prompt {
    overflow: hidden;
    width: var(--cell-width-tablet);
    min-height: 50px;
    font-size: 12px;
  }
  .row-prompt{
  height: var(--cell-width-tablet);
  }
  .row {
    height: var(--cell-width-tablet);
    gap: 3px;
  }

  .box {
    width: var(--cell-width-tablet);
    font-size: 14px;
    padding: 4px;
  }

  .country-info {
    font-size: 10px;
  }

  .rarity-element {
    font-size: 8px;
  }

  .highlighted {
    background-color: rgb(230, 230, 123) !important;
  }

  .flag-container{
    margin: 4px 0;
  }
}

@media screen and (max-width: 600px) {
  .top-nav {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
}

@media screen and (max-width: 480px) {
  .board{
    margin: 0;
    gap: 2px;
  }
  .filler{
    width: var(--cell-width-mobile);
  }
  .prompt {
    width: var(--cell-width-mobile);
    font-size: 10px;
    padding: 8px;
  }
  .row-prompt {
    height: var(--cell-width-mobile);
  }
  .row {
    height: var(--cell-width-mobile);
    gap: 2px;
  }
  .box {
    width: var(--cell-width-mobile);
  }
  .guesses-and-score{
    font-size: 14px;
  }
  .country-info {
    font-size: 8px;
  }

  .rarity-element {
    font-size: 7px;
  }
}

@media screen and (max-width: 375px) {
  .filler{
    width: 80px;
  }
  .prompt {
    width: 80px;
  }
  .row-prompt {
    height: 80px;
  }
  .row {
    height: 80px;
  }
  .box {
    width: 80px;
  }
  .page-title {
    font-size: 26px;
  }
}
@keyframes shake {
  0% {
    transform: translateX(0);
  }

  20% {
    transform: translateX(-8px);
  }

  40% {
    transform: translateX(8px);
  }

  60% {
    transform: translateX(-8px);
  }

  80% {
    transform: translateX(8px);
  }

  100% {
    transform: translateX(0);
  }
}

.animate-shake {
  animation: shake 0.5s;
}

@keyframes gradientMove {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
</style>