<template>
  <div v-if="!hidden" ref="toastRef" class="toast-container" role="alert">
    <div class="toast-content">
      <div class="toast-body">
        <span class="toast-title">
          {{ title }}
        </span>
        <div class="toast-message">{{ body }}</div>
        <div v-if="primaryButton">
          <button @click="primaryButton.onClick" class="toast-button toast-primary-button">
            {{ primaryButton.label }}
          </button>
        </div>
      </div>
      <button v-if="!hideCloseButton" type="button" @click="animateClose" class="toast-close-button" aria-label="Close">
        <svg aria-hidden="true" class="toast-close-icon" fill="currentColor" viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z">
          </path>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from 'vue';

export default {
  name: 'interactiveToast',
  props: {
    title: {
      type: String,
      required: true,
    },
    body: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
      default: true,
    },
    primaryButton: {
      type: Object,
      default: null,
    },
    onClose: {
      type: Function,
      default: null,
    },
    hideCloseButton: {
      type: Boolean,
      default: false,
    },
    autoClose: {
      type: Number,
      default: null,
    },
  },
  setup(props) {
    const toastRef = ref(null);
    const hidden = ref(!props.show);

    const animateClose = () => {
      if (props.onClose) {
        props.onClose();
      }
      if (!toastRef.value) return;
      toastRef.value.classList.remove('animate-reveal');
      toastRef.value.classList.add('animate-fadeOut');
      setTimeout(() => {
        if (toastRef.value) {
          hidden.value = true;
        }
      }, 200);
    };

    const animateShow = () => {
      hidden.value = false;
      if (!toastRef.value) return;
      toastRef.value.classList.remove('animate-fadeOut');
      toastRef.value.classList.add('animate-reveal');
    };

    onMounted(() => {
      const timeouts = [];
      if (props.show) {
        animateShow();
        if (props.autoClose) {
          timeouts.push(
            setTimeout(() => {
              animateClose();
            }, props.autoClose)
          );
        }
      } else {
        const timeout = animateClose();
        if (timeout) timeouts.push(timeout);
      }
      onBeforeUnmount(() => timeouts.forEach((timeout) => clearTimeout(timeout)));
    });

    return {
      toastRef,
      hidden,
      animateClose,
    };
  },
};
</script>

<style scoped>
.toast-container {
  width: 100%;
  z-index: 99991;
  position: fixed;
  top: 16px;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  pointer-events: none;
}

.toast-content {
  display: flex;
  background-color: rgb(73, 73, 90);
  border-radius: 8px;
  padding: 16px;
  pointer-events: auto;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.toast-body {
  margin-left: 12px;
  font-size: 14px;
}

.toast-title {
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: 600;
  color: rgb(223, 223, 223);
}

.toast-message {
  margin-bottom: 8px;
  font-size: 14px;
  color: rgb(223, 223, 223);
}

.toast-button {
  display: inline-flex;
  justify-content: center;
  width: 100%;
  padding: 4px 8px;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  border-radius: 6px;
  cursor: pointer;
}

.toast-primary-button {
  color: rgb(223, 223, 223);
  background-color: #2563eb;
  border: none;
}

.toast-primary-button:hover {
  background-color: #1d4ed8;
}

.toast-close-button {
  margin-left: auto;
  margin-right: -6px;
  margin-top: -6px;
  background-color: rgb(73, 73, 90);
  color: rgb(223, 223, 223);
  border: none;
  border-radius: 8px;
  padding: 4px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
}

.toast-close-icon {
  width: 20px;
  height: 20px;
}

@keyframes reveal {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(-10px);
  }
}

.animate-reveal {
  animation: reveal 0.3s ease forwards;
}

.animate-fadeOut {
  animation: fadeOut 0.3s ease forwards;
}
</style>
