<template>
  <interactiveToast :key="isUpdate" title="Update Available" body="A new content update is available" :show="isUpdate"
    :primaryButton="{
      label: 'Refresh',
      onClick: newReloadPage
    }" />
</template>

<script>
import { ref, onMounted, onBeforeUnmount, computed } from 'vue';
import { getVersion } from '@/utils/fetchDataFromCDN';
import interactiveToast from './interactiveToast.vue';

export default {
  components: {
    interactiveToast,
  },
  setup() {
    const version = ref(Number(localStorage.getItem('cdn-version') || 0));
    const updateVersion = ref(0);

    const newReloadPage = () => {
      localStorage.setItem('cdn-version', updateVersion.value);
      if (typeof location !== 'undefined') location.reload();
    };

    const checkVersion = async () => {
      const cver = await getVersion();
      if (cver > version.value && updateVersion.value !== cver) {
        updateVersion.value = cver;
      }
    };

    onMounted(async () => {
      const ver = await getVersion();
      version.value = ver;
      localStorage.setItem('cdn-version', ver);
      checkVersion();
      const verCheck = setInterval(checkVersion, 20000);

      onBeforeUnmount(() => {
        clearInterval(verCheck);
      });
    });

    const isUpdate = computed(() => {
      return (
        Number(version.value) !== Number(updateVersion.value) &&
        Number(updateVersion.value) > 0 &&
        Number(version.value) > 0
      )
    });

    return {
      isUpdate,
      newReloadPage,
    };
  },
};
</script>
